<template>
  <!-- App.vue -->

  <v-app>
    <v-app-bar app color="white" flat>
      <v-container class="py-0 fill-height">
        <router-link to="/"
          ><v-avatar class="mr-5" :tile="true">
            <img
              :src="require('@/assets/logo.png')"
              alt="onehourattorney.com"
            /> </v-avatar
        ></router-link>
        OneHourAttorney.com
        <!-- <router-link to="/">
          <v-avatar :tile="true" width="250">
            <img
              :src="require('@/assets/text_logo.png')"
              alt="logo"
            /> </v-avatar
        ></router-link> -->
        <!-- <v-spacer></v-spacer> -->
        <!-- <v-img
          :src="require('@/assets/text_logo.png')"
          alt="OneHourAttorney.com"
          max-height="40"
          contain
        /> -->

        <!-- <v-btn v-for="link in links" :key="link" text>
          {{ link }}
        </v-btn> -->
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <!-- <v-col cols="2">
            <v-sheet rounded="lg">
              <v-list color="transparent">
                <v-list-item v-for="n in 5" :key="n" link>
                  <v-list-item-content>
                    <v-list-item-title> List Item {{ n }} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider class="my-2"></v-divider>

                <v-list-item link color="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title> Refresh </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col> -->

          <v-col>
            <v-sheet min-height="70vh" rounded="lg">
              <router-view></router-view>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer app>
      <v-container class="py-0 fill-height">
        <v-btn
          v-show="$route.name !== 'home'"
          :to="{ name: 'home' }"
          text
          x-small
          plain
        >
          Return to calendar
        </v-btn>
        <!-- <v-btn :to="{ name: 'refundPolicy' }" text x-small plain>
          Refund policy
        </v-btn> -->
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
