<template>
  <sign-contract />
</template>

<script>
import SignContract from "../components/SignContract";

export default {
  name: "ContractView",

  components: {
    SignContract,
  },
};
</script>
