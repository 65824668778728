<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="8" md="7" sm="12">
        <v-card class="mx-auto" max-width="700" min-width="250" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">Jennifer Horwitz</div>
              <v-list-item-title class="text-h5 mb-1 text-wrap">
                Experience. Compassion. Results.
              </v-list-item-title>
              <v-list-item-subtitle
                >A Strategic Approach to Complex Cases</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-avatar tile size="120">
              <v-img :src="require('../assets/jenniferhorwitz.png')"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-text>
            <p>
              Jennifer Horwitz is a Harvard-educated criminal defense attorney
              with 30 years of experience. Her practice areas include criminal
              cases in state and federal court, protection order cases of all
              types, and pre-charge representations. Because of her depth of
              experience, Jennifer is able to provide advice and a framework for
              approaching whatever your issue may be in a paid one-hour
              consultation. Some examples of the services that she provides in
              these consultations are: explanations of how certain cases or
              investigations work, advice after a contact by law enforcement or
              the news that someone has filed a police report, information about
              how to effectively pursue a protection order on your own (without
              paying a lawyer for full representation), and how to protect
              yourself against a false allegation that you are worried will be
              made. Jennifer has a network of referrals that she can provide for
              further representation if needed, if she is unable to take your
              case or if your case is outside of her main practice areas.
            </p>
            <p>
              Please click on any bold date on her calendar to see her
              availability and instantly schedule your virtual appointment
              (phone, Zoom, etc).
            </p>
          </v-card-text>

          <!-- <v-card-actions>
            <v-btn @click="showFirstAvail" outlined text>
              Show first availability
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12"
        ><v-sheet class="mx-auto" max-width="700" min-width="300"
          ><v-date-picker
            color="grey"
            v-model="selectedDate"
            class="mt-4"
            :min="today"
            :allowedDates="allowedDates"
            full-width
          ></v-date-picker></v-sheet
      ></v-col>
    </v-row>

    <v-dialog v-model="showModal" scrollable width="600px">
      <v-card>
        <v-card-title>
          {{
            selectedDate | luxon({ input: "inputdate", output: "outputdate" })
          }}
        </v-card-title>
        <v-card-subtitle
          >Click on one of the available slots below</v-card-subtitle
        >
        <v-card-text>
          <v-list>
            <template v-for="slot in slotsByDate[selectedDate]">
              <v-list-item :key="slot.id" @click="confirmSlot(slot)">
                {{ slot.lawyer }} -
                {{ slot.start.toISO() | luxon({ output: "outputtime" }) }}
                - {{ slot.minutes }} min (${{ slot.cost }})
              </v-list-item></template
            >
          </v-list>
        </v-card-text>
        <v-card-actions
          ><v-btn @click="showModal = false">close</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import router from "@/router";
export default {
  name: "DisplayCalendar",

  data: () => ({
    showModal: false,
    selectedSlot: "",
    selectedDate: "",
    today: new Date().toISOString().substring(0, 10),
    daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  }),
  created() {
    this.loadEvents();
    this.getJurisdictions();
  },
  computed: {
    ...mapState(["slots", "slotsByDate"]),
  },
  methods: {
    ...mapActions([
      "loadEvents",
      "getJurisdictions",
      "pushSelectedSlot",

      // `mapMutations` also supports payloads:
      // 'incrementBy' // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
    ]),
    ...mapMutations(["pushSelectedSlot"]),
    confirmSlot(slot) {
      this.pushSelectedSlot(slot);
      router.push({ name: "form" });
    },
    allowedDates(val) {
      return val in this.slotsByDate;
    },
  },
  watch: {
    selectedDate() {
      this.showModal = true;
    },
  },
};
</script>
