import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import moment from "moment";
import { VueMaskDirective } from "v-mask";
import VueGtag from "vue-gtag";
Vue.directive("mask", VueMaskDirective);
Amplify.configure(awsExports);

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: "G-1ZFD1ZT9GH" },
});

import VueLuxon from "vue-luxon";
Vue.use(VueLuxon, {
  templates: {
    inputdate: {
      zone: "client",
      format: "yyyy-MM-dd",
    },
    outputdate: {
      zone: "client",
      format: "DDDD",
    },
    outputtime: {
      zone: "client",
      format: "t",
    },
  },
  input: {
    zone: "utc",
    format: "iso",
  },
  output: {
    zone: "local",
    format: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    locale: null,
    relative: {
      round: false,
      unit: null,
    },
  },
});

Vue.use(require("vue-moment"));

Vue.use(require("vue-cookies"));

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("ddd, MMM D YYYY");
  }
});

Vue.filter("formatTime", function (value) {
  if (value) {
    return moment(String(value)).format("h:mm a");
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
