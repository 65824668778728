<template>
  <refund-policy />
</template>

<script>
import RefundPolicy from "../components/RefundPolicy";

export default {
  name: "RefundView",

  components: {
    RefundPolicy,
  },
};
</script>
