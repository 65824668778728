<template>
  <final-component />
</template>

<script>
import FinalComponent from "../components/FinalComponent";

export default {
  name: "FinalView",

  components: {
    FinalComponent,
  },
};
</script>
