<template>
  <wait-for-load />
</template>

<script>
import WaitForLoad from "../components/WaitComponent";

export default {
  name: "WaitView",

  components: {
    WaitForLoad,
  },
};
</script>
