<template>
  <file-upload />
</template>

<script>
import FileUpload from "../components/FileUpload";

export default {
  name: "BookingView",

  components: {
    FileUpload,
  },
};
</script>
