export const createBookingIndividual = /* GraphQL */ `
  mutation CreateBookingIndividual(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      customerFirstName
      customerLastName
      customerEmail
      customerPhone
      docketNumber
      opposingpartys {
        items {
          id
          opposingPartyID
          bookingID
          opposingParty {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      startTime
      endTime
      Lawyers {
        items {
          id
          bookingID
          lawyerID
          lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      signatureDateTime
      customerSignature
      slotID
      chiefComplaint
      slotGoogleUUID
      slotCalendarID
      jurisdiction
      subJurisdiction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
