import Vue from "vue";
import Vuex from "vuex";
import { Booking, OpposingParty } from "../models";
// import router from "@/router";
import axios from "axios";
import { API, graphqlOperation } from "aws-amplify";
import {
  createOpposingParty,
  createOpposingPartyBooking,
  // updateBooking,
} from "../graphql/mutations";
import { getBooking, listJurisdictions } from "@/graphql/queries";
import { getContract, listSlotsOnly } from "@/graphql/custom_queries";
import { createBookingIndividual } from "@/graphql/custom_mutations";
import Auth from "@aws-amplify/auth";
import router from "@/router";
import { DateTime } from "luxon";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    jurisdictionList: [],
    jurisdiction: "",
    subJurisdiction: "",
    chiefComplaint: "",
    customerFirstName: "",
    customerLastName: "",
    customerEmail: "",
    customerPhone: "",
    customerUsername: "",
    customerSignature: "",
    contract: "",
    signatureDateTime: "",
    docketNumber: "",
    opposingParties: [],
    booking: "",
    slots: [],
    selectedSlot: null,
    slotsByDate: {},
  },
  getters: {},
  mutations: {
    // Simple mutations
    jurisdiction(state, event) {
      state.jurisdiction = event;
    },
    subJurisdiction(state, event) {
      state.subJurisdiction = event;
    },
    chiefComplaint(state, event) {
      state.chiefComplaint = event;
    },
    customerFirstName(state, event) {
      state.customerFirstName = event;
    },
    customerLastName(state, event) {
      state.customerLastName = event;
    },
    customerEmail(state, event) {
      state.customerEmail = event;
    },
    customerPhone(state, event) {
      state.customerPhone = event;
    },
    customerUsername(state, event) {
      state.customerUsername = event;
    },
    customerSignature(state, event) {
      state.customerSignature = event;
    },
    signatureDateTime(state, event) {
      state.signatureDateTime = event;
    },
    docketNumber(state, event) {
      state.docketNumber = event;
    },
    // Below here are complex mutations
    pushOpposingParty(state, event) {
      // Don't want to add blanks and don't want to add ones already there
      if ((state.opposingParties.indexOf(event) == -1) & (event != "")) {
        state.opposingParties.push(event);
      }
    },
    removeOpposingParty(state, event) {
      state.opposingParties.splice(state.opposingParties.indexOf(event), 1);
    },
    pushSelectedSlot(state, event) {
      state.selectedSlot = event;
    },
    async setBooking(state, event) {
      console.log("event");
      console.log(event);
      await API.graphql(graphqlOperation(getBooking, { id: event })).then(
        (response) => {
          console.log("booking");
          console.log(response);
          state.booking = response["data"]["getBooking"];
        }
      );
    },
  },
  actions: {
    async getJurisdictions(context) {
      await API.graphql(graphqlOperation(listJurisdictions))
        .then((response) => {
          console.log("response jurisdictions: ", response);
          context.state.jurisdictionList =
            response["data"]["listJurisdictions"]["items"];
          const propComparator = (propName) => (a, b) =>
            a[propName].toLowerCase() == b[propName].toLowerCase()
              ? 0
              : a[propName].toLowerCase() < b[propName].toLowerCase()
              ? -1
              : 1;

          context.state.jurisdictionList.sort(propComparator(`county`));
        })
        .catch((error) => {
          console.log("error at attempt to get events from api");
          console.log(error);
        });
      // context.state.jurisdictionList = await DataStore.query(Jurisdiction);
    },
    async createOpposingParty(context, payload) {
      var op = "";
      await API.graphql(
        graphqlOperation(createOpposingParty, { input: payload })
      ).then((response) => {
        console.log("opposing party");
        console.log(response);
        op = response.data.createOpposingParty;
      });
      await API.graphql(
        graphqlOperation(createOpposingPartyBooking, {
          input: {
            opposingPartyID: op.id,
            bookingID: context.state.booking.id,
          },
        })
      ).then((response) => {
        console.log("opposing party booking");
        console.log(response);
      });
    },
    async createOpposingParties(context) {
      context.state.opposingParties.forEach((opposingParty) => {
        var op = new OpposingParty({
          name: opposingParty,
        });
        context.dispatch("createOpposingParty", op);
      });
    },
    async verifyLogin() {
      await Auth.currentAuthenticatedUser()
        .then(
          (user2) => {
            console.log("user2 in");
            console.log(user2);
            return true;
          },
          (error) => {
            console.log("error at login");
            console.log(error);
          }
        )
        .catch((error) => {
          console.log("catch error1");
          console.log(error);
        });
      return false;
    },
    async makeBooking(context) {
      console.log("at booking");
      // var owner;
      // await Auth.currentAuthenticatedUser()
      //   .then(
      //     (user2) => {
      //       owner = user2;
      //     },
      //     (error) => {
      //       console.log("error at login");
      //       console.log(error);
      //     }
      //   )
      //   .catch((error) => {
      //     console.log("catch error1");
      //     console.log(error);
      //   });
      // console.log("beyond verif;y");
      // console.log(owner.attributes);
      // owner = owner["attributes"]["sub"];
      context.state.booking = await new Booking({
        chiefComplaint: context.state.chiefComplaint,
        customerFirstName: context.state.customerFirstName,
        customerLastName: context.state.customerLastName,
        customerEmail: context.state.customerEmail,
        customerPhone: context.state.customerPhone,
        docketNumber: context.state.docketNumber,
        startTime: context.state.selectedSlot.start.toISO(),
        endTime: context.state.selectedSlot.end.toISO(),
        slotID: context.state.selectedSlot.id,
        jurisdiction: context.state.jurisdiction.county,
        subJurisdiction: context.state.subJurisdiction,
      });
      console.log(context.state.booking);
      await API.graphql(
        graphqlOperation(createBookingIndividual, {
          input: context.state.booking,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })
      )
        .then((response) => {
          console.log("booking");
          context.state.booking = response.data.createBooking;
          console.log(context.state.booking);
        })
        .catch((error) => {
          console.log("error making booking");
          console.log(error);
        });

      await context.dispatch("createOpposingParties");
      await API.graphql(
        graphqlOperation(getContract, {
          id: context.state.booking.slotID,
        })
      )
        .then((response) => {
          console.log("slot");
          console.log(response);
          let contractUrl = response["data"]["getSlot"]["Calendar"]["contract"];
          context.state.contract = contractUrl;
          console.log("contracturl: ", context.state.contract);
          // axios.get(contractUrl).then((response) => {
          //   console.log("contract html");
          //   console.log(response);
          //   context.state.contract = response["data"];
          // });
        })
        .catch((error) => {
          console.log("error at get contract: ", error);
        });
      // router.push({ name: "contract" });
    },
    async confirmSignature(context, bookingid) {
      console.log(context.state.customerSignature);
      try {
        const this_jwt = Vue.$cookies.get("jwt");
        if (this_jwt == null) {
          alert("Your session has expired");
          router.push("/");
        } else {
          // await API.graphql(
          //   graphqlOperation(updateBooking, {
          //     input: {
          //       id: bookingid,
          //       customerSignature: context.state.customerSignature,
          //       signatureDateTime:
          //         context.state.signatureDateTime.toISOString(),
          //     },
          //   })
          // ).then((response) => {
          //   console.log("updated booking");
          //   console.log(response);
          //   context.state.booking = response.data.updateBooking;
          // });
          // send customer to payment page
          axios
            .post(
              "https://hpbfjwe5mrlgys64indhqq6n6q0ilbqr.lambda-url.us-west-2.on.aws/",
              {
                bookingid: bookingid,
                customerSignature: context.state.customerSignature,
                signatureDateTime:
                  context.state.signatureDateTime.toISOString(),
                jwt: this_jwt,
              }
            )
            .then((response) => {
              console.log(response);
              if (response["error"] != null) {
                console.log("error: ", response["error"]);
                router.push("/");
              } else {
                window.location.href = response["data"]["link"];
              }
            });
        }
      } catch (err) {
        console.log(err);
      }

      // submit signature
      /* Models in DataStore are immutable. To update a record you must use the copyOf function
 to apply updates to the item’s fields rather than mutating the instance directly */

      // API
      // context.state.booking = new Booking({
      //   customerFirstName: context.state.customerFirstName,
      //   customerLastName: context.state.customerLastName,
      //   customerEmail: context.state.customerEmail,
      //   customerPhone: context.state.customerPhone,
      //   docketNumber: context.state.docketNumber,
      // });
      // context.state.booking = await DataStore.save(context.state.booking);
      // await context.dispatch("createOpposingParties");
      // await DataStore.save(
      //   new BookingJurisdiction({
      //     booking: context.state.booking,
      //     jurisdiction: context.state.jurisdiction,
      //   })
      // );
    },
    checkSlotSelected(context) {
      if (context.state.selectedSlot == null) {
        router.push("/");
      }
    },
    async loadEvents(context) {
      const events = [];
      const eventsByDate = {};
      var models = [];
      var moreToLoad = true;
      var nextToken = null;

      while (moreToLoad) {
        await API.graphql(
          graphqlOperation(listSlotsOnly, {
            nextToken: nextToken,
            authMode: "AMAZON_COGNITO_USER_POOLS",
          })
        )
          .then((response) => {
            const thisModels = response["data"]["listSlots"]["items"];
            models = models.concat(thisModels);
            nextToken = response["data"]["listSlots"]["nextToken"];
            if (nextToken == null) {
              moreToLoad = false;
            }
          })
          .catch((error) => {
            console.log("error at attempt to get events from api 3");
            console.log(error);
            moreToLoad = false;
          });
      }

      console.log(models);
      // let dates = [15, 16, 17, 18, 19, 20, 21];
      // let times = [1, 1, 1, 1, 1, 1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      // dates.forEach((i) => {
      //   times.forEach((j) => {
      //     models.push({
      //       id: "id" + i,
      //       startTime: moment("2022-11-" + i + " " + j + ":00 PST"),
      //       endTime: moment("2022-11-" + i + " " + (j + 1) + ":00 PST"),
      //       price: 500,
      //       calendarId: 19349348,
      //       Calendar: { Lawyer: { firstName: "Steve", lastName: "Rosen" } },
      //     });
      //   });
      // });
      // models = [{ id: "id" , startTime: ''}];

      models.forEach((slot) => {
        let start = DateTime.fromISO(slot.startTime);
        let end = DateTime.fromISO(slot.endTime);
        let td = end.diff(start, "minutes").as("minutes");
        let startDate = start.toISO().substring(0, 10);
        let thisItem = {
          name: "Available",
          id: slot.id,
          start: start,
          end: end,
          timed: true,
          color: "red",
          cost: slot.price,
          calendarId: slot.slotCalendarId,
          minutes: td,
          lawyer:
            slot.Calendar.Lawyer.firstName.substring(0, 1) +
            " " +
            slot.Calendar.Lawyer.lastName,
        };
        events.push(thisItem);
        if (startDate in eventsByDate) {
          eventsByDate[startDate].push(thisItem);
        } else {
          eventsByDate[startDate] = [thisItem];
        }
      });

      // eslint-disable-next-line
      Object.entries(eventsByDate).forEach(([k, v]) => {
        v.sort(function (a, b) {
          return a.start - b.start;
        });
      });

      context.state.slots = events;

      console.log("eventsByDate");
      console.log(eventsByDate);

      context.state.slotsByDate = eventsByDate;
    },
  },
  modules: {},
});
