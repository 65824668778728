<template>
  <v-container>
    <v-row>
      <v-col class="mb-5" cols="12">
        <v-card class="mx-auto" max-width="700" min-width="250" outlined>
          <v-list-item two-line>
            <v-list-item-content>
              <div class="text-overline mb-4">Jennifer Horwitz</div>
              <v-list-item-title class="text-h5 mb-1 text-wrap">
                {{ selectedSlot.minutes }} minute consult- ${{
                  selectedSlot.cost
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ selectedSlot.start.toISO() | luxon("full") }} -
                {{ selectedSlot.end.toISO() | luxon("time") }} ({{
                  selectedSlot.start.toISO() | luxonRelative
                }})
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="120">
              <v-img :src="require('../assets/jenniferhorwitz.png')"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-text> </v-card-text>

          <!-- <v-card-actions>
            <v-btn @click="showFirstAvail" outlined text>
              Show first availability
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-col>
    </v-row>
    <v-stepper v-model="e6" vertical class="elevation-0 ma-0 pa-0">
      <v-stepper-step :complete="e6 > 1" step="1">
        Your Information
        <small class="red--text">Required</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="validateHead">
            <v-row>
              <v-col cols="12" md="6">
                <ValidationProvider
                  name="customerFirstName"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="customerFirstName"
                    label="*First Name"
                    :error-messages="errors"
                  ></v-text-field
                ></ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  name="customerLastName"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="customerLastName"
                    label="*Last Name"
                    :error-messages="errors"
                  ></v-text-field
                ></ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <ValidationProvider
                  name="customerEmail"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="customerEmail"
                    label="*Email"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  name="localPhone"
                  rules="required|length:17"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="localPhone"
                    label="*Phone"
                    :error-messages="errors"
                    v-mask="'+1 (###) ###-####'"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-btn color="primary" type="submit" :disabled="invalid">
              Continue
            </v-btn>
          </form>
        </ValidationObserver>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2">
        Case Information
        <small class="black--text"
          >Please fill in any available information</small
        >
      </v-stepper-step>

      <v-stepper-content step="2">
        <ValidationObserver v-slot="{ invalid }">
          <form
            @submit.prevent="validateSecond"
            @keydown.enter="$event.preventDefault()"
          >
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  name="chiefComplaint"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    v-model="chiefComplaint"
                    label="*Explain your legal concern"
                    :error-messages="errors"
                    auto-grow
                    rows="1"
                    hint="Please tell us what your case is about and how we can help you"
                    persistent-hint
                  ></v-textarea
                ></ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="darken-2"
                ><v-btn small outlined @click="showPleading = true"
                  >Where to find necessary information from legal forms</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="jurisdiction"
                      :items="jurisdictionList"
                      item-text="county"
                      item-value="id"
                      label="Select Jurisdiction"
                      hint="Select county of case or your county if no case filed"
                      persistent-hint
                      return-object
                      single-line
                    ></v-autocomplete>
                  </v-col>
                  <v-col v-if="jurisdiction != ''">
                    <v-autocomplete
                      v-model="subJurisdiction"
                      :items="jurisdiction.courts"
                      item-text="county"
                      item-value="id"
                      label="Select Court"
                      persistent-hint
                      return-object
                      single-line
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="docketNumber"
                      label="Case Number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h3 class="subheadline font-weight-bold mb-3">
                      Opposing parties/accuser:
                    </h3>
                    We'll need to check for conflicts of interest. Please let us
                    know the names of the opposing party or parties
                    <u>and</u> the accusing person (if any). This information is
                    usually found the the caption of the legal document (such as
                    Jane v. John). If this a criminal case, please list the
                    government agency that is involved (such as City of Seattle
                    v. Doe)
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="opposingParty"
                      label="Opposing Party/Accuser"
                      v-on:keyup.enter="appendOpposingParty"
                      ref="opposingPartyTextBox"
                      hint="Type opposing party/accuser name and click the Add button"
                      persistent-hint
                    >
                      <template #append>
                        <v-btn @click="appendOpposingParty"> Add </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col cols="6">
                    <v-chip
                      v-for="op in opposingParties"
                      v-bind:key="op"
                      class="ma-2"
                      close
                      @click:close="removeOP(op)"
                    >
                      {{ op }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
              <v-dialog v-model="showPleading" width="600px">
                <v-card
                  ><v-img
                    lazy-src="../assets/pleading.png"
                    src="../assets/pleading.png"
                  ></v-img
                  ><v-btn @click="showPleading = false">Close</v-btn></v-card
                >
              </v-dialog>
            </v-row>
            <v-row
              ><v-col
                ><v-btn color="primary" type="submit" :disabled="invalid">
                  Continue
                </v-btn></v-col
              ></v-row
            >
          </form>
        </ValidationObserver>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="3">
        Verify Your Mobile Number
        <small class="red--text">Required</small>
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-row>
          <v-col cols="12">
            We need to verify your identity using your phone number
            {{ localPhone }}. Please click <b>VERIFY</b> below to receive a 6
            digit code via text message from us for identity verification. We
            will not use your information for promotional purposes.
          </v-col>
        </v-row>
        <v-btn color="primary" @click="sendVerify"> Verify </v-btn>
      </v-stepper-content>
      <v-dialog v-model="showOTP" width="600px">
        <v-card class="pa-2" flat>
          <v-card-text>
            <v-form>
              <v-container>
                <p v-if="verificationMode === 'email'" class="text-center">
                  A verification code was sent to your email ({{
                    customerEmail
                  }}).
                </p>
                <p v-if="verificationMode === 'phone'" class="text-center">
                  A verification code was sent to your phone ({{
                    customerPhone
                  }}).
                </p>
                <p class="text-center">Please enter it here:</p>
                <v-row>
                  <v-spacer></v-spacer>
                  <div
                    class="ma-auto position-relative"
                    style="max-width: 300px"
                  >
                    <v-otp-input
                      ref="otpVerify"
                      v-model="verifyCode"
                      :disabled="loading"
                      @finish="verifyAndMakeBooking"
                      type="number"
                    ></v-otp-input>
                    <v-overlay absolute :value="loading">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                  </div>

                  <v-spacer></v-spacer>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
        {{ snackbarText }}
      </v-snackbar>

      <v-stepper-step :complete="e6 > 4" step="4">
        Retainer Agreement
      </v-stepper-step>

      <v-stepper-content step="4" class="d-none d-lg-block">
        <!-- Make sure anything changed here also gets changed below -->
        <v-card outlined>
          <!-- <div v-html="contract"></div> -->
          <iframe id="ifrm" :src="contract" width="100%" height="500"></iframe>
        </v-card>
        <v-row class="text-center">
          <v-col class="mb-5" cols="12">
            <h2 class="headline mb-3">
              By clicking the "Sign" button, you indicate that you have read and
              agree to the above contract
            </h2>
            <v-btn
              color="primary"
              @click="
                showModal = true;
                signatureDateTime = new Date();
              "
              >Sign</v-btn
            >
          </v-col>
        </v-row>
        <v-overlay :value="overlay">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular
        ></v-overlay>
      </v-stepper-content>
    </v-stepper>
    <v-sheet class="d-lg-none" v-if="e6 == 4">
      <!-- this is a copy of the above, just outside of the stepper class -->
      <v-card outlined>
        <!-- <div v-html="contract"></div> -->
        <iframe id="ifrm" :src="contract" width="100%" height="500"></iframe>
      </v-card>
      <v-row class="text-center">
        <v-col class="mb-5" cols="12">
          <h2 class="headline mb-3">
            By clicking the "Sign" button, you indicate that you have read and
            agree to the above contract
          </h2>
          <v-btn
            color="primary"
            @click="
              showModal = true;
              signatureDateTime = new Date();
            "
            >Sign</v-btn
          >
        </v-col>
      </v-row>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate color="primary"></v-progress-circular
      ></v-overlay>
    </v-sheet>
    <v-dialog v-model="showModal" width="600px">
      <v-card>
        <v-card-title>Signature:</v-card-title>
        <v-card-text
          >By entering your name in the following box, you indicate that you
          have read and agree to the contract</v-card-text
        >
        <ValidationObserver v-slot="{ invalid }">
          <form
            @submit.prevent="
              overlay = true;
              showModal = false;
              confirmSignature(bookingid);
            "
          >
            <v-card-text>
              <ValidationProvider
                name="customerSignature"
                rules="required"
                v-slot="{ errors }"
                ><v-text-field
                  v-model="customerSignature"
                  label="Sign by typing your full name"
                  :error-messages="errors"
                  required
                ></v-text-field> </ValidationProvider
            ></v-card-text>
            <v-card-text
              >Date/time of signature: {{ signatureDateTime }}</v-card-text
            >
            <v-card-text
              ><v-btn color="primary" type="submit" :disabled="invalid">
                Click Here to Sign
              </v-btn></v-card-text
            >
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required, email, digits, length } from "vee-validate/dist/rules";
import axios from "axios";
// import VuePhoneNumberInput from "vue-phone-number-input";
// import "vue-phone-number-input/dist/vue-phone-number-input.css";
// No message specified.
extend("email", {
  ...email,
  message: "Please enter a valid email address.",
});

// No message specified.
extend("digits", {
  ...digits,
  message: "Please enter a valid 10 digit phone number.",
});

// No message specified.
extend("length", {
  ...length,
  message: "Please enter a valid 10 digit phone number.",
});

// Override the default message.
extend("required", {
  ...required,
  message: "Required.",
});

export default {
  name: "SignUp",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    opposingParty: "",
    e6: 1,
    showModal: false,
    showOTP: false,
    loading: false,
    overlay: false,
    verifyCode: "",
    verificationMode: "phone",
    snackbarColor: "default",
    snackbar: false,
    snackbarText: "",
    verifysid: "",
    showPleading: false,
    localPhone: "",
    postParams: {},
    contract: "",
    bookingid: "",
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
  }),
  created() {
    this.checkSlotSelected();
    this.getJurisdictions();
  },
  computed: {
    ...mapState(["jurisdictionList", "opposingParties", "selectedSlot"]),
    jurisdiction: {
      ...mapState({ get: "jurisdiction" }),
      ...mapMutations({ set: "jurisdiction" }),
    },
    subJurisdiction: {
      ...mapState({ get: "subJurisdiction" }),
      ...mapMutations({ set: "subJurisdiction" }),
    },
    chiefComplaint: {
      ...mapState({ get: "chiefComplaint" }),
      ...mapMutations({ set: "chiefComplaint" }),
    },
    customerFirstName: {
      ...mapState({ get: "customerFirstName" }),
      ...mapMutations({ set: "customerFirstName" }),
    },
    customerLastName: {
      ...mapState({ get: "customerLastName" }),
      ...mapMutations({ set: "customerLastName" }),
    },
    customerEmail: {
      ...mapState({ get: "customerEmail" }),
      ...mapMutations({ set: "customerEmail" }),
    },
    customerUsername: {
      ...mapState({ get: "customerUsername" }),
      ...mapMutations({ set: "customerUsername" }),
    },
    customerPhone: {
      ...mapState({ get: "customerPhone" }),
      ...mapMutations({ set: "customerPhone" }),
    },
    customerSignature: {
      ...mapState({ get: "customerSignature" }),
      ...mapMutations({ set: "customerSignature" }),
    },
    signatureDateTime: {
      ...mapState({ get: "signatureDateTime" }),
      ...mapMutations({ set: "signatureDateTime" }),
    },
    docketNumber: {
      ...mapState({ get: "docketNumber" }),
      ...mapMutations({ set: "docketNumber" }),
    },
  },
  methods: {
    ...mapActions([
      "getJurisdictions",
      "makeBooking",
      "confirmSignature",
      "verifyLogin",
      "checkSlotSelected",

      // `mapMutations` also supports payloads:
      // 'incrementBy' // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
    ]),
    ...mapMutations(["pushOpposingParty", "removeOpposingParty"]),
    // Will need some local only ones too
    // below here is local
    appendOpposingParty() {
      this.pushOpposingParty(this.opposingParty);
      this.opposingParty = "";
      this.$refs.opposingPartyTextBox.focus();
    },
    removeOP(op) {
      this.removeOpposingParty(op);
      this.opposingParty = "";
      this.$refs.opposingPartyTextBox.focus();
    },
    async sendVerify() {
      this.loading = true;
      this.showOTP = true;
      const phoneUtil =
        require("google-libphonenumber").PhoneNumberUtil.getInstance();
      var tel = phoneUtil.parse(this.localPhone, "US");
      var PNF = require("google-libphonenumber").PhoneNumberFormat;
      this.customerPhone = phoneUtil.format(tel, PNF.E164);
      this.verificationMode = "phone";
      this.postParams["verification_mode"] = this.verificationMode;
      this.postParams["to_addr"] = this.customerPhone;
      axios
        .post(
          "https://i7jccun6pxn7pqad7khb7h3v5a0upnjt.lambda-url.us-west-2.on.aws",
          this.postParams
        )
        .then((response) => {
          this.verifysid = response.data.sid;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.showOTP = false;
          this.snackbarColor = "warning";
          this.snackbarText =
            "There was an error sending the code. Please check your phone number.";
          this.snackbar = true;
        });
    },
    async verifyAndMakeBooking() {
      this.loading = true;
      this.postParams["opposing_parties"] = this.opposingParties;
      this.postParams["otp"] = this.verifyCode;
      this.postParams["chiefComplaint"] = this.chiefComplaint;
      this.postParams["customerFirstName"] = this.customerFirstName;
      this.postParams["customerLastName"] = this.customerLastName;
      this.postParams["customerEmail"] = this.customerEmail;
      this.postParams["customerPhone"] = this.customerPhone;
      this.postParams["docketNumber"] = this.docketNumber;
      this.postParams["startTime"] = this.selectedSlot.start.toISO();
      this.postParams["endTime"] = this.selectedSlot.end.toISO();
      this.postParams["slotID"] = this.selectedSlot.id;
      this.postParams["jurisdiction"] = this.jurisdiction.county;
      this.postParams["subJurisdiction"] = this.subJurisdiction;
      axios
        .post(
          "https://eeg7ve36onnydnhvdxoodipxvy0frgnb.lambda-url.us-west-2.on.aws",
          this.postParams
        )
        .then((response) => {
          console.log(response);
          this.$cookies.set("jwt", response["data"]["jwt"], "1h");
          this.contract = response.data.contract_url;
          this.bookingid = response.data.booking_id;
          this.showOTP = false;
          this.e6 = 4;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.snackbarColor = "warning";
          this.snackbarText = "Incorrect code!";
          this.snackbar = true;
          this.loading = false;
        });
    },
    validateHead() {
      this.e6 = 2;
    },
    validateSecond() {
      this.e6 = 3;
    },
  },
};
</script>
