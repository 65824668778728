// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Payment, OpposingParty, Booking, Lawyer, Jurisdiction, Admin, Calendar, Slot, OpposingPartyBooking, BookingLawyer, CalendarAdmin } = initSchema(schema);

export {
  Payment,
  OpposingParty,
  Booking,
  Lawyer,
  Jurisdiction,
  Admin,
  Calendar,
  Slot,
  OpposingPartyBooking,
  BookingLawyer,
  CalendarAdmin
};