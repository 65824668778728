/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      external_id
      status
      bookingID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        external_id
        status
        bookingID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPayments = /* GraphQL */ `
  query SyncPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        external_id
        status
        bookingID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOpposingParty = /* GraphQL */ `
  query GetOpposingParty($id: ID!) {
    getOpposingParty(id: $id) {
      id
      name
      bookings {
        items {
          id
          opposingPartyID
          bookingID
          opposingParty {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOpposingParties = /* GraphQL */ `
  query ListOpposingParties(
    $filter: ModelOpposingPartyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpposingParties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        bookings {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOpposingParties = /* GraphQL */ `
  query SyncOpposingParties(
    $filter: ModelOpposingPartyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOpposingParties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        bookings {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getJurisdiction = /* GraphQL */ `
  query GetJurisdiction($id: ID!) {
    getJurisdiction(id: $id) {
      id
      county
      courts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listJurisdictions = /* GraphQL */ `
  query ListJurisdictions(
    $filter: ModelJurisdictionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJurisdictions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        county
        courts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncJurisdictions = /* GraphQL */ `
  query SyncJurisdictions(
    $filter: ModelJurisdictionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJurisdictions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        county
        courts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      customerFirstName
      customerLastName
      customerEmail
      customerPhone
      docketNumber
      opposingpartys {
        items {
          id
          opposingPartyID
          bookingID
          opposingParty {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      startTime
      endTime
      Lawyers {
        items {
          id
          bookingID
          lawyerID
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      signatureDateTime
      customerSignature
      Payments {
        items {
          id
          external_id
          status
          bookingID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      slotID
      chiefComplaint
      slotGoogleUUID
      slotCalendarID
      jurisdiction
      subJurisdiction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerFirstName
        customerLastName
        customerEmail
        customerPhone
        docketNumber
        opposingpartys {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        startTime
        endTime
        Lawyers {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        signatureDateTime
        customerSignature
        Payments {
          items {
            id
            external_id
            status
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        slotID
        chiefComplaint
        slotGoogleUUID
        slotCalendarID
        jurisdiction
        subJurisdiction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBookings = /* GraphQL */ `
  query SyncBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customerFirstName
        customerLastName
        customerEmail
        customerPhone
        docketNumber
        opposingpartys {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        startTime
        endTime
        Lawyers {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        signatureDateTime
        customerSignature
        Payments {
          items {
            id
            external_id
            status
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        slotID
        chiefComplaint
        slotGoogleUUID
        slotCalendarID
        jurisdiction
        subJurisdiction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      email
      calendars {
        items {
          id
          adminID
          calendarID
          admin {
            id
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          calendar {
            id
            googleID
            name
            watchID
            watchResourceID
            watchExpiration
            contract
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calendarLawyerId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        calendars {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdmins = /* GraphQL */ `
  query SyncAdmins(
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdmins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        calendars {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSlot = /* GraphQL */ `
  query GetSlot($id: ID!) {
    getSlot(id: $id) {
      id
      name
      startTime
      endTime
      price
      googleUUID
      Calendar {
        id
        googleID
        name
        Admins {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Lawyer {
          id
          firstName
          lastName
          description
          email
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        watchID
        watchResourceID
        watchExpiration
        contract
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calendarLawyerId
      }
      Bookings {
        items {
          id
          customerFirstName
          customerLastName
          customerEmail
          customerPhone
          docketNumber
          opposingpartys {
            nextToken
            startedAt
          }
          startTime
          endTime
          Lawyers {
            nextToken
            startedAt
          }
          signatureDateTime
          customerSignature
          Payments {
            nextToken
            startedAt
          }
          slotID
          chiefComplaint
          slotGoogleUUID
          slotCalendarID
          jurisdiction
          subJurisdiction
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      statu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      slotCalendarId
    }
  }
`;
export const listSlots = /* GraphQL */ `
  query ListSlots(
    $filter: ModelSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startTime
        endTime
        price
        googleUUID
        Calendar {
          id
          googleID
          name
          Admins {
            nextToken
            startedAt
          }
          Lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          watchID
          watchResourceID
          watchExpiration
          contract
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          calendarLawyerId
        }
        Bookings {
          items {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        statu
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        slotCalendarId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSlots = /* GraphQL */ `
  query SyncSlots(
    $filter: ModelSlotFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSlots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        startTime
        endTime
        price
        googleUUID
        Calendar {
          id
          googleID
          name
          Admins {
            nextToken
            startedAt
          }
          Lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          watchID
          watchResourceID
          watchExpiration
          contract
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          calendarLawyerId
        }
        Bookings {
          items {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        statu
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        slotCalendarId
      }
      nextToken
      startedAt
    }
  }
`;
export const getCalendar = /* GraphQL */ `
  query GetCalendar($id: ID!) {
    getCalendar(id: $id) {
      id
      googleID
      name
      Admins {
        items {
          id
          adminID
          calendarID
          admin {
            id
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          calendar {
            id
            googleID
            name
            watchID
            watchResourceID
            watchExpiration
            contract
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calendarLawyerId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Lawyer {
        id
        firstName
        lastName
        description
        email
        bookings {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      watchID
      watchResourceID
      watchExpiration
      contract
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      calendarLawyerId
    }
  }
`;
export const listCalendars = /* GraphQL */ `
  query ListCalendars(
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        googleID
        name
        Admins {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Lawyer {
          id
          firstName
          lastName
          description
          email
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        watchID
        watchResourceID
        watchExpiration
        contract
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calendarLawyerId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCalendars = /* GraphQL */ `
  query SyncCalendars(
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCalendars(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        googleID
        name
        Admins {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Lawyer {
          id
          firstName
          lastName
          description
          email
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        watchID
        watchResourceID
        watchExpiration
        contract
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calendarLawyerId
      }
      nextToken
      startedAt
    }
  }
`;
export const getLawyer = /* GraphQL */ `
  query GetLawyer($id: ID!) {
    getLawyer(id: $id) {
      id
      firstName
      lastName
      description
      email
      bookings {
        items {
          id
          bookingID
          lawyerID
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLawyers = /* GraphQL */ `
  query ListLawyers(
    $filter: ModelLawyerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLawyers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        description
        email
        bookings {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLawyers = /* GraphQL */ `
  query SyncLawyers(
    $filter: ModelLawyerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLawyers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        description
        email
        bookings {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOpposingPartyBooking = /* GraphQL */ `
  query GetOpposingPartyBooking($id: ID!) {
    getOpposingPartyBooking(id: $id) {
      id
      opposingPartyID
      bookingID
      opposingParty {
        id
        name
        bookings {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      booking {
        id
        customerFirstName
        customerLastName
        customerEmail
        customerPhone
        docketNumber
        opposingpartys {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        startTime
        endTime
        Lawyers {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        signatureDateTime
        customerSignature
        Payments {
          items {
            id
            external_id
            status
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        slotID
        chiefComplaint
        slotGoogleUUID
        slotCalendarID
        jurisdiction
        subJurisdiction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOpposingPartyBookings = /* GraphQL */ `
  query ListOpposingPartyBookings(
    $filter: ModelOpposingPartyBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpposingPartyBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        opposingPartyID
        bookingID
        opposingParty {
          id
          name
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        booking {
          id
          customerFirstName
          customerLastName
          customerEmail
          customerPhone
          docketNumber
          opposingpartys {
            nextToken
            startedAt
          }
          startTime
          endTime
          Lawyers {
            nextToken
            startedAt
          }
          signatureDateTime
          customerSignature
          Payments {
            nextToken
            startedAt
          }
          slotID
          chiefComplaint
          slotGoogleUUID
          slotCalendarID
          jurisdiction
          subJurisdiction
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOpposingPartyBookings = /* GraphQL */ `
  query SyncOpposingPartyBookings(
    $filter: ModelOpposingPartyBookingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOpposingPartyBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        opposingPartyID
        bookingID
        opposingParty {
          id
          name
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        booking {
          id
          customerFirstName
          customerLastName
          customerEmail
          customerPhone
          docketNumber
          opposingpartys {
            nextToken
            startedAt
          }
          startTime
          endTime
          Lawyers {
            nextToken
            startedAt
          }
          signatureDateTime
          customerSignature
          Payments {
            nextToken
            startedAt
          }
          slotID
          chiefComplaint
          slotGoogleUUID
          slotCalendarID
          jurisdiction
          subJurisdiction
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBookingLawyer = /* GraphQL */ `
  query GetBookingLawyer($id: ID!) {
    getBookingLawyer(id: $id) {
      id
      bookingID
      lawyerID
      booking {
        id
        customerFirstName
        customerLastName
        customerEmail
        customerPhone
        docketNumber
        opposingpartys {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        startTime
        endTime
        Lawyers {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        signatureDateTime
        customerSignature
        Payments {
          items {
            id
            external_id
            status
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        slotID
        chiefComplaint
        slotGoogleUUID
        slotCalendarID
        jurisdiction
        subJurisdiction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lawyer {
        id
        firstName
        lastName
        description
        email
        bookings {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBookingLawyers = /* GraphQL */ `
  query ListBookingLawyers(
    $filter: ModelBookingLawyerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingLawyers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bookingID
        lawyerID
        booking {
          id
          customerFirstName
          customerLastName
          customerEmail
          customerPhone
          docketNumber
          opposingpartys {
            nextToken
            startedAt
          }
          startTime
          endTime
          Lawyers {
            nextToken
            startedAt
          }
          signatureDateTime
          customerSignature
          Payments {
            nextToken
            startedAt
          }
          slotID
          chiefComplaint
          slotGoogleUUID
          slotCalendarID
          jurisdiction
          subJurisdiction
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lawyer {
          id
          firstName
          lastName
          description
          email
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBookingLawyers = /* GraphQL */ `
  query SyncBookingLawyers(
    $filter: ModelBookingLawyerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookingLawyers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        bookingID
        lawyerID
        booking {
          id
          customerFirstName
          customerLastName
          customerEmail
          customerPhone
          docketNumber
          opposingpartys {
            nextToken
            startedAt
          }
          startTime
          endTime
          Lawyers {
            nextToken
            startedAt
          }
          signatureDateTime
          customerSignature
          Payments {
            nextToken
            startedAt
          }
          slotID
          chiefComplaint
          slotGoogleUUID
          slotCalendarID
          jurisdiction
          subJurisdiction
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        lawyer {
          id
          firstName
          lastName
          description
          email
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCalendarAdmin = /* GraphQL */ `
  query GetCalendarAdmin($id: ID!) {
    getCalendarAdmin(id: $id) {
      id
      adminID
      calendarID
      admin {
        id
        email
        calendars {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      calendar {
        id
        googleID
        name
        Admins {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Lawyer {
          id
          firstName
          lastName
          description
          email
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        watchID
        watchResourceID
        watchExpiration
        contract
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calendarLawyerId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCalendarAdmins = /* GraphQL */ `
  query ListCalendarAdmins(
    $filter: ModelCalendarAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        adminID
        calendarID
        admin {
          id
          email
          calendars {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        calendar {
          id
          googleID
          name
          Admins {
            nextToken
            startedAt
          }
          Lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          watchID
          watchResourceID
          watchExpiration
          contract
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          calendarLawyerId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCalendarAdmins = /* GraphQL */ `
  query SyncCalendarAdmins(
    $filter: ModelCalendarAdminFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCalendarAdmins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        adminID
        calendarID
        admin {
          id
          email
          calendars {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        calendar {
          id
          googleID
          name
          Admins {
            nextToken
            startedAt
          }
          Lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          watchID
          watchResourceID
          watchExpiration
          contract
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          calendarLawyerId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
