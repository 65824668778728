<template>
  <v-card class="d-flex flex-wrap justify-center" outlined color="transparent">
    <v-card outlined color="transparent" max-width="800">
      <v-card-title>Refund Policy</v-card-title>
      <v-card-text>
        <p>
          All refunds are subject to a 5% cancellation fee. No refunds will be
          issued within 14 days of an appointment.
        </p>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "RefundPolicy",

  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>
