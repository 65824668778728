<template>
  <v-card class="d-flex flex-wrap justify-center" outlined color="transparent">
    <v-overlay :value="loading"
      ><v-row justify="center" align="center">
        <v-col cols="1" sm="1">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col> </v-row
    ></v-overlay>
    <v-row v-if="loading" justify="center" align="center">
      <v-col cols="12" sm="4">
        Please wait while we {{ process }} the booking.
      </v-col>
    </v-row>
    <v-dialog v-model="startingDialog" width="600px">
      <v-card class="pa-2" flat>
        <v-card-title>Confirm or cancel booking</v-card-title>
        <v-card-text
          >Please select whether you would like to confirm or cancel the
          booking</v-card-text
        >
        <v-card-actions
          ><v-btn @click="confirmOrRefund('confirm')">Confirm</v-btn>
          <v-btn @click="confirmOrRefund('cancel')"
            >Cancel</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-card v-if="booking" outlined color="transparent" max-width="800">
      <v-card-title>Booking has been {{ bookingStatus }}</v-card-title>
      <v-card-text>
        <p v-if="!meetingDetails">
          <b style="color: red"
            >PLEASE CONTACT THE CUSTOMER TO ARRANGE A ZOOM OR PHONE MEETING</b
          >
        </p>
        <p>
          You should receive an email with all of the information about this
          booking
        </p>
        <p>
          Please reach out to the customer to verify that they understand the
          status of this booking
        </p>
        <p>
          Customer name: {{ booking.customerFirstName }}
          {{ booking.customerLastName }}
        </p>
        <p>Customer email: {{ booking.customerEmail }}</p>
        <p>Customer phone: {{ booking.customerPhone }}</p>
      </v-card-text>
      <v-dialog v-model="dialog" width="600px">
        <v-card class="pa-2" flat>
          <p>This booking was not confirmed!</p>
          <p>
            If this was your intention, please reach out to the customer at
            {{ booking.customerEmail }}, to explain and seek a remedy
          </p>
          <p>
            If this was not your intention, please reach out to the help desk
          </p></v-card
        >
      </v-dialog>
    </v-card>
  </v-card>
</template>

<script>
// eslint-disable-next-line
import axios from "axios";
// import { mapMutations, mapState } from "vuex";

export default {
  name: "FileUpload",

  data: () => ({
    bookingStatus: "",
    dialog: false,
    paymentID: "",
    process: "",
    booking: false,
    loading: false,
    startingDialog: true,
    meetingDetails: false,
  }),
  created() {
    this.paymentID = this.$route.params.id;
  },
  computed: {},
  methods: {
    async confirmOrRefund(process) {
      this.process = process;
      this.loading = true;
      this.startingDialog = false;
      axios
        .post(
          "https://wi2gz6althghhi27ldlhzyvbxe0dufgm.lambda-url.us-west-2.on.aws",
          { payment_id: this.paymentID, process: process }
        )
        .then((response) => {
          console.log(response);
          this.booking = JSON.parse(response["data"]["booking"]);
          if ("meetingDetails" in response["data"]) {
            this.meetingDetails = response["data"]["meetingDetails"];
          }
          this.bookingStatus = response["data"]["status"];
          if (this.bookingStatus == "cancelled") {
            this.dialog = true;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
