<template>
  <sign-up />
</template>

<script>
import SignUp from "../components/SignUp";

export default {
  name: "FormView",

  components: {
    SignUp,
  },
};
</script>
