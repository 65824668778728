<template>
  <v-container fill-height>
    <v-overlay :value="overlay"
      ><v-row justify="center" align="center">
        <v-col cols="1" sm="1">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col> </v-row
    ></v-overlay>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="4">
        Thank you for booking your appointment with us! Please wait while we
        reserve time on the attorney's calendar and put a hold on the funds on
        your card. Do not click refresh or back - we should be finished in less
        than 30 seconds. Thank you.
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import router from "@/router";
export default {
  name: "WaitForLoad",
  overlay: true,
  data: () => ({
    session_id: "",
    files: [],
    e1: 0,
    documents: [],
    e_Mail: "tt",
    emailRules: [],
    dialog: false,
  }),
  created() {
    this.session_id = this.$route.params.sessionid;
    this.sendSession();
  },
  computed: {},
  methods: {
    async sendSession() {
      const url =
        "https://mklr55ngq54tln6irz4elfvlf40hnjed.lambda-url.us-west-2.on.aws/?session_id=" +
        this.session_id;
      axios
        .get(url)
        .then((response) => {
          console.log(response);
          const booking_id = response["data"]["booking_id"];
          router.push({ name: "postBooking", params: { id: booking_id } });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
