<template>
  <display-calendar />
</template>

<script>
import DisplayCalendar from "../components/DisplayCalendar";

export default {
  name: "TestView",

  components: {
    DisplayCalendar,
  },
};
</script>
