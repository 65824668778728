/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      external_id
      status
      bookingID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      external_id
      status
      bookingID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      external_id
      status
      bookingID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createOpposingParty = /* GraphQL */ `
  mutation CreateOpposingParty(
    $input: CreateOpposingPartyInput!
    $condition: ModelOpposingPartyConditionInput
  ) {
    createOpposingParty(input: $input, condition: $condition) {
      id
      name
      bookings {
        items {
          id
          opposingPartyID
          bookingID
          opposingParty {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateOpposingParty = /* GraphQL */ `
  mutation UpdateOpposingParty(
    $input: UpdateOpposingPartyInput!
    $condition: ModelOpposingPartyConditionInput
  ) {
    updateOpposingParty(input: $input, condition: $condition) {
      id
      name
      bookings {
        items {
          id
          opposingPartyID
          bookingID
          opposingParty {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteOpposingParty = /* GraphQL */ `
  mutation DeleteOpposingParty(
    $input: DeleteOpposingPartyInput!
    $condition: ModelOpposingPartyConditionInput
  ) {
    deleteOpposingParty(input: $input, condition: $condition) {
      id
      name
      bookings {
        items {
          id
          opposingPartyID
          bookingID
          opposingParty {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createJurisdiction = /* GraphQL */ `
  mutation CreateJurisdiction(
    $input: CreateJurisdictionInput!
    $condition: ModelJurisdictionConditionInput
  ) {
    createJurisdiction(input: $input, condition: $condition) {
      id
      county
      courts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateJurisdiction = /* GraphQL */ `
  mutation UpdateJurisdiction(
    $input: UpdateJurisdictionInput!
    $condition: ModelJurisdictionConditionInput
  ) {
    updateJurisdiction(input: $input, condition: $condition) {
      id
      county
      courts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteJurisdiction = /* GraphQL */ `
  mutation DeleteJurisdiction(
    $input: DeleteJurisdictionInput!
    $condition: ModelJurisdictionConditionInput
  ) {
    deleteJurisdiction(input: $input, condition: $condition) {
      id
      county
      courts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      customerFirstName
      customerLastName
      customerEmail
      customerPhone
      docketNumber
      opposingpartys {
        items {
          id
          opposingPartyID
          bookingID
          opposingParty {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      startTime
      endTime
      Lawyers {
        items {
          id
          bookingID
          lawyerID
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      signatureDateTime
      customerSignature
      Payments {
        items {
          id
          external_id
          status
          bookingID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      slotID
      chiefComplaint
      slotGoogleUUID
      slotCalendarID
      jurisdiction
      subJurisdiction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      customerFirstName
      customerLastName
      customerEmail
      customerPhone
      docketNumber
      opposingpartys {
        items {
          id
          opposingPartyID
          bookingID
          opposingParty {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      startTime
      endTime
      Lawyers {
        items {
          id
          bookingID
          lawyerID
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      signatureDateTime
      customerSignature
      Payments {
        items {
          id
          external_id
          status
          bookingID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      slotID
      chiefComplaint
      slotGoogleUUID
      slotCalendarID
      jurisdiction
      subJurisdiction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      customerFirstName
      customerLastName
      customerEmail
      customerPhone
      docketNumber
      opposingpartys {
        items {
          id
          opposingPartyID
          bookingID
          opposingParty {
            id
            name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      startTime
      endTime
      Lawyers {
        items {
          id
          bookingID
          lawyerID
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      signatureDateTime
      customerSignature
      Payments {
        items {
          id
          external_id
          status
          bookingID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      slotID
      chiefComplaint
      slotGoogleUUID
      slotCalendarID
      jurisdiction
      subJurisdiction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      email
      calendars {
        items {
          id
          adminID
          calendarID
          admin {
            id
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          calendar {
            id
            googleID
            name
            watchID
            watchResourceID
            watchExpiration
            contract
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calendarLawyerId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      email
      calendars {
        items {
          id
          adminID
          calendarID
          admin {
            id
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          calendar {
            id
            googleID
            name
            watchID
            watchResourceID
            watchExpiration
            contract
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calendarLawyerId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      email
      calendars {
        items {
          id
          adminID
          calendarID
          admin {
            id
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          calendar {
            id
            googleID
            name
            watchID
            watchResourceID
            watchExpiration
            contract
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calendarLawyerId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSlot = /* GraphQL */ `
  mutation CreateSlot(
    $input: CreateSlotInput!
    $condition: ModelSlotConditionInput
  ) {
    createSlot(input: $input, condition: $condition) {
      id
      name
      startTime
      endTime
      price
      googleUUID
      Calendar {
        id
        googleID
        name
        Admins {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Lawyer {
          id
          firstName
          lastName
          description
          email
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        watchID
        watchResourceID
        watchExpiration
        contract
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calendarLawyerId
      }
      Bookings {
        items {
          id
          customerFirstName
          customerLastName
          customerEmail
          customerPhone
          docketNumber
          opposingpartys {
            nextToken
            startedAt
          }
          startTime
          endTime
          Lawyers {
            nextToken
            startedAt
          }
          signatureDateTime
          customerSignature
          Payments {
            nextToken
            startedAt
          }
          slotID
          chiefComplaint
          slotGoogleUUID
          slotCalendarID
          jurisdiction
          subJurisdiction
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      statu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      slotCalendarId
    }
  }
`;
export const updateSlot = /* GraphQL */ `
  mutation UpdateSlot(
    $input: UpdateSlotInput!
    $condition: ModelSlotConditionInput
  ) {
    updateSlot(input: $input, condition: $condition) {
      id
      name
      startTime
      endTime
      price
      googleUUID
      Calendar {
        id
        googleID
        name
        Admins {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Lawyer {
          id
          firstName
          lastName
          description
          email
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        watchID
        watchResourceID
        watchExpiration
        contract
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calendarLawyerId
      }
      Bookings {
        items {
          id
          customerFirstName
          customerLastName
          customerEmail
          customerPhone
          docketNumber
          opposingpartys {
            nextToken
            startedAt
          }
          startTime
          endTime
          Lawyers {
            nextToken
            startedAt
          }
          signatureDateTime
          customerSignature
          Payments {
            nextToken
            startedAt
          }
          slotID
          chiefComplaint
          slotGoogleUUID
          slotCalendarID
          jurisdiction
          subJurisdiction
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      statu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      slotCalendarId
    }
  }
`;
export const deleteSlot = /* GraphQL */ `
  mutation DeleteSlot(
    $input: DeleteSlotInput!
    $condition: ModelSlotConditionInput
  ) {
    deleteSlot(input: $input, condition: $condition) {
      id
      name
      startTime
      endTime
      price
      googleUUID
      Calendar {
        id
        googleID
        name
        Admins {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Lawyer {
          id
          firstName
          lastName
          description
          email
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        watchID
        watchResourceID
        watchExpiration
        contract
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calendarLawyerId
      }
      Bookings {
        items {
          id
          customerFirstName
          customerLastName
          customerEmail
          customerPhone
          docketNumber
          opposingpartys {
            nextToken
            startedAt
          }
          startTime
          endTime
          Lawyers {
            nextToken
            startedAt
          }
          signatureDateTime
          customerSignature
          Payments {
            nextToken
            startedAt
          }
          slotID
          chiefComplaint
          slotGoogleUUID
          slotCalendarID
          jurisdiction
          subJurisdiction
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      statu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      slotCalendarId
    }
  }
`;
export const createCalendar = /* GraphQL */ `
  mutation CreateCalendar(
    $input: CreateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    createCalendar(input: $input, condition: $condition) {
      id
      googleID
      name
      Admins {
        items {
          id
          adminID
          calendarID
          admin {
            id
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          calendar {
            id
            googleID
            name
            watchID
            watchResourceID
            watchExpiration
            contract
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calendarLawyerId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Lawyer {
        id
        firstName
        lastName
        description
        email
        bookings {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      watchID
      watchResourceID
      watchExpiration
      contract
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      calendarLawyerId
    }
  }
`;
export const updateCalendar = /* GraphQL */ `
  mutation UpdateCalendar(
    $input: UpdateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    updateCalendar(input: $input, condition: $condition) {
      id
      googleID
      name
      Admins {
        items {
          id
          adminID
          calendarID
          admin {
            id
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          calendar {
            id
            googleID
            name
            watchID
            watchResourceID
            watchExpiration
            contract
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calendarLawyerId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Lawyer {
        id
        firstName
        lastName
        description
        email
        bookings {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      watchID
      watchResourceID
      watchExpiration
      contract
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      calendarLawyerId
    }
  }
`;
export const deleteCalendar = /* GraphQL */ `
  mutation DeleteCalendar(
    $input: DeleteCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    deleteCalendar(input: $input, condition: $condition) {
      id
      googleID
      name
      Admins {
        items {
          id
          adminID
          calendarID
          admin {
            id
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          calendar {
            id
            googleID
            name
            watchID
            watchResourceID
            watchExpiration
            contract
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calendarLawyerId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Lawyer {
        id
        firstName
        lastName
        description
        email
        bookings {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      watchID
      watchResourceID
      watchExpiration
      contract
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      calendarLawyerId
    }
  }
`;
export const createLawyer = /* GraphQL */ `
  mutation CreateLawyer(
    $input: CreateLawyerInput!
    $condition: ModelLawyerConditionInput
  ) {
    createLawyer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      description
      email
      bookings {
        items {
          id
          bookingID
          lawyerID
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLawyer = /* GraphQL */ `
  mutation UpdateLawyer(
    $input: UpdateLawyerInput!
    $condition: ModelLawyerConditionInput
  ) {
    updateLawyer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      description
      email
      bookings {
        items {
          id
          bookingID
          lawyerID
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLawyer = /* GraphQL */ `
  mutation DeleteLawyer(
    $input: DeleteLawyerInput!
    $condition: ModelLawyerConditionInput
  ) {
    deleteLawyer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      description
      email
      bookings {
        items {
          id
          bookingID
          lawyerID
          booking {
            id
            customerFirstName
            customerLastName
            customerEmail
            customerPhone
            docketNumber
            startTime
            endTime
            signatureDateTime
            customerSignature
            slotID
            chiefComplaint
            slotGoogleUUID
            slotCalendarID
            jurisdiction
            subJurisdiction
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          lawyer {
            id
            firstName
            lastName
            description
            email
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createOpposingPartyBooking = /* GraphQL */ `
  mutation CreateOpposingPartyBooking(
    $input: CreateOpposingPartyBookingInput!
    $condition: ModelOpposingPartyBookingConditionInput
  ) {
    createOpposingPartyBooking(input: $input, condition: $condition) {
      id
      opposingPartyID
      bookingID
      opposingParty {
        id
        name
        bookings {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      booking {
        id
        customerFirstName
        customerLastName
        customerEmail
        customerPhone
        docketNumber
        opposingpartys {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        startTime
        endTime
        Lawyers {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        signatureDateTime
        customerSignature
        Payments {
          items {
            id
            external_id
            status
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        slotID
        chiefComplaint
        slotGoogleUUID
        slotCalendarID
        jurisdiction
        subJurisdiction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateOpposingPartyBooking = /* GraphQL */ `
  mutation UpdateOpposingPartyBooking(
    $input: UpdateOpposingPartyBookingInput!
    $condition: ModelOpposingPartyBookingConditionInput
  ) {
    updateOpposingPartyBooking(input: $input, condition: $condition) {
      id
      opposingPartyID
      bookingID
      opposingParty {
        id
        name
        bookings {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      booking {
        id
        customerFirstName
        customerLastName
        customerEmail
        customerPhone
        docketNumber
        opposingpartys {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        startTime
        endTime
        Lawyers {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        signatureDateTime
        customerSignature
        Payments {
          items {
            id
            external_id
            status
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        slotID
        chiefComplaint
        slotGoogleUUID
        slotCalendarID
        jurisdiction
        subJurisdiction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteOpposingPartyBooking = /* GraphQL */ `
  mutation DeleteOpposingPartyBooking(
    $input: DeleteOpposingPartyBookingInput!
    $condition: ModelOpposingPartyBookingConditionInput
  ) {
    deleteOpposingPartyBooking(input: $input, condition: $condition) {
      id
      opposingPartyID
      bookingID
      opposingParty {
        id
        name
        bookings {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      booking {
        id
        customerFirstName
        customerLastName
        customerEmail
        customerPhone
        docketNumber
        opposingpartys {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        startTime
        endTime
        Lawyers {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        signatureDateTime
        customerSignature
        Payments {
          items {
            id
            external_id
            status
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        slotID
        chiefComplaint
        slotGoogleUUID
        slotCalendarID
        jurisdiction
        subJurisdiction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBookingLawyer = /* GraphQL */ `
  mutation CreateBookingLawyer(
    $input: CreateBookingLawyerInput!
    $condition: ModelBookingLawyerConditionInput
  ) {
    createBookingLawyer(input: $input, condition: $condition) {
      id
      bookingID
      lawyerID
      booking {
        id
        customerFirstName
        customerLastName
        customerEmail
        customerPhone
        docketNumber
        opposingpartys {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        startTime
        endTime
        Lawyers {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        signatureDateTime
        customerSignature
        Payments {
          items {
            id
            external_id
            status
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        slotID
        chiefComplaint
        slotGoogleUUID
        slotCalendarID
        jurisdiction
        subJurisdiction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lawyer {
        id
        firstName
        lastName
        description
        email
        bookings {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBookingLawyer = /* GraphQL */ `
  mutation UpdateBookingLawyer(
    $input: UpdateBookingLawyerInput!
    $condition: ModelBookingLawyerConditionInput
  ) {
    updateBookingLawyer(input: $input, condition: $condition) {
      id
      bookingID
      lawyerID
      booking {
        id
        customerFirstName
        customerLastName
        customerEmail
        customerPhone
        docketNumber
        opposingpartys {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        startTime
        endTime
        Lawyers {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        signatureDateTime
        customerSignature
        Payments {
          items {
            id
            external_id
            status
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        slotID
        chiefComplaint
        slotGoogleUUID
        slotCalendarID
        jurisdiction
        subJurisdiction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lawyer {
        id
        firstName
        lastName
        description
        email
        bookings {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBookingLawyer = /* GraphQL */ `
  mutation DeleteBookingLawyer(
    $input: DeleteBookingLawyerInput!
    $condition: ModelBookingLawyerConditionInput
  ) {
    deleteBookingLawyer(input: $input, condition: $condition) {
      id
      bookingID
      lawyerID
      booking {
        id
        customerFirstName
        customerLastName
        customerEmail
        customerPhone
        docketNumber
        opposingpartys {
          items {
            id
            opposingPartyID
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        startTime
        endTime
        Lawyers {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        signatureDateTime
        customerSignature
        Payments {
          items {
            id
            external_id
            status
            bookingID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        slotID
        chiefComplaint
        slotGoogleUUID
        slotCalendarID
        jurisdiction
        subJurisdiction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lawyer {
        id
        firstName
        lastName
        description
        email
        bookings {
          items {
            id
            bookingID
            lawyerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCalendarAdmin = /* GraphQL */ `
  mutation CreateCalendarAdmin(
    $input: CreateCalendarAdminInput!
    $condition: ModelCalendarAdminConditionInput
  ) {
    createCalendarAdmin(input: $input, condition: $condition) {
      id
      adminID
      calendarID
      admin {
        id
        email
        calendars {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      calendar {
        id
        googleID
        name
        Admins {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Lawyer {
          id
          firstName
          lastName
          description
          email
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        watchID
        watchResourceID
        watchExpiration
        contract
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calendarLawyerId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCalendarAdmin = /* GraphQL */ `
  mutation UpdateCalendarAdmin(
    $input: UpdateCalendarAdminInput!
    $condition: ModelCalendarAdminConditionInput
  ) {
    updateCalendarAdmin(input: $input, condition: $condition) {
      id
      adminID
      calendarID
      admin {
        id
        email
        calendars {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      calendar {
        id
        googleID
        name
        Admins {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Lawyer {
          id
          firstName
          lastName
          description
          email
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        watchID
        watchResourceID
        watchExpiration
        contract
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calendarLawyerId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCalendarAdmin = /* GraphQL */ `
  mutation DeleteCalendarAdmin(
    $input: DeleteCalendarAdminInput!
    $condition: ModelCalendarAdminConditionInput
  ) {
    deleteCalendarAdmin(input: $input, condition: $condition) {
      id
      adminID
      calendarID
      admin {
        id
        email
        calendars {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      calendar {
        id
        googleID
        name
        Admins {
          items {
            id
            adminID
            calendarID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        Lawyer {
          id
          firstName
          lastName
          description
          email
          bookings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        watchID
        watchResourceID
        watchExpiration
        contract
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calendarLawyerId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
