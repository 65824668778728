<template>
  <v-card class="d-flex flex-wrap justify-center" outlined color="transparent">
    <v-card outlined color="transparent" max-width="800">
      <v-card-title>Booking complete</v-card-title>
      <v-card-text>
        <p>
          Thank you. We have reserved your time on your attorney's calendar.
        </p>

        <p>
          Your lawyer will verify that she/he does not have any conflicts and
          get back to you within 1 business day.
        </p>

        <p>
          You will receive a follow up email verifying that there is no conflict
          and confirming your appointment.
        </p>

        <p>
          If, in the unlikely event that the lawyer does have a conflict with
          your case, you will be contacted via email.
        </p>

        <p>
          Until the appointment is confirmed, your card will not be charged, but
          there is a hold for the total anticipated amount pending on your card.
        </p>

        <p>
          In the meantime, please upload any documents for your lawyer to review
          (up to 20 pages).
        </p>

        <p>
          PLEASE CHECK YOUR EMAIL AND YOUR SPAM FOLDER. WE HAVE SENT YOU AN
          EMAIL WITH YOUR APPOINTMENT INFORMATION.
        </p>

        <p>You may now close this browser.</p>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "FinalComponent",

  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>
