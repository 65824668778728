export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getSlot(id: $id) {
      Calendar {
        contract
      }
    }
  }
`;
export const listSlotsOnly = /* GraphQL */ `
  query ListSlotsObly(
    $filter: ModelSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startTime
        endTime
        price
        Calendar {
          id
          name
          Lawyer {
            id
            firstName
            lastName
            description
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
