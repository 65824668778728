import Vue from "vue";
import VueRouter from "vue-router";
import FormView from "../views/FormView.vue";
import ContractView from "../views/ContractView.vue";
import BookingView from "../views/BookingView.vue";
import TestView from "../views/TestView.vue";
import WaitView from "../views/WaitView.vue";
import ConfirmView from "../views/ConfirmView.vue";
import RefundView from "../views/RefundView.vue";
import FinalView from "../views/FinalView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: TestView,
    meta: {
      title: "Home - OneHourAttorney.com",
    },
  },
  {
    path: "/form",
    name: "form",
    component: FormView,
    meta: {
      title: "Book Your Appointment - OneHourAttorney.com",
    },
  },
  {
    path: "/contract",
    name: "contract",
    component: ContractView,
  },
  {
    path: "/payment/:sessionid",
    name: "wait",
    component: WaitView,
    meta: {
      title: "Booking Your Appointment - OneHourAttorney.com",
    },
  },
  {
    path: "/booking/:id",
    name: "postBooking",
    component: BookingView,
    meta: {
      title: "Upload Files - OneHourAttorney.com",
    },
  },
  {
    path: "/confirmOrRefund/:id",
    name: "confirmOrRefund",
    component: ConfirmView,
    meta: {
      title: "Booking Status - OneHourAttorney.com",
    },
  },
  {
    path: "/refundPolicy",
    name: "refundPolicy",
    component: RefundView,
    meta: {
      title: "Refund Policy - OneHourAttorney.com",
    },
  },
  {
    path: "/final/",
    name: "final",
    component: FinalView,
    meta: {
      title: "Booking Status - OneHourAttorney.com",
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/test/",
    name: "test",
    component: TestView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

// ...

export default router;
