<template>
  <booked-component />
</template>

<script>
import BookedComponent from "../components/BookedComponent";

export default {
  name: "ConfirmView",

  components: {
    BookedComponent,
  },
};
</script>
