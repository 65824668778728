<template>
  <v-card class="d-flex flex-wrap justify-center" outlined color="transparent">
    <v-overlay :value="loadingBooking"
      ><v-row justify="center" align="center">
        <v-col cols="1" sm="1">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col> </v-row
    ></v-overlay>
    <v-card v-if="booking" outlined color="transparent" max-width="800">
      <v-card-title style="word-break: break-word"
        >Please upload any files for your attorney to review</v-card-title
      >
      <v-card-text>
        <p>
          Your attorney will review up to 20 pages before your appointment.
          Please consider uploading your legal documents to our secure server. A
          link to this page is in your email.
        </p>
        <p class="d-sm-none">
          Touch below to upload. Files and photos may be uploaded from your
          mobile device.
        </p>
        <p class="d-none d-sm-block">
          You may access this page from your mobile device and use your phone
          camera to scan any documents.
        </p>
        <p class="d-none d-sm-block">
          <v-btn @click="sendSMS" :loading="sendingSMS"
            >Click here to send a link to the phone on file</v-btn
          >
        </p>
        <v-file-input
          v-model="files"
          ref="file"
          label="Select files to upload"
          @change="fileSelected"
          outlined
          multiple
        >
        </v-file-input>
        <p v-if="this.currentlyUploading != ''">
          Currently uploading {{ currentlyUploading }}
          <v-progress-linear :value="uploadPercentage"></v-progress-linear>
        </p>
        <v-alert
          border="right"
          colored-border
          color="red"
          elevation="2"
          v-if="failed.length"
        >
          <p>Upload failed (consider trying again): <v-divider></v-divider></p>
          <p v-for="document in failed" v-bind:key="document.name">
            {{ document.name }}
          </p>
        </v-alert>
        <v-alert
          border="right"
          colored-border
          elevation="2"
          v-if="uploading.length"
          ><p>Awaiting upload: <v-divider></v-divider></p>

          <p v-for="document in uploading" v-bind:key="document.name">
            {{ document.name }}
          </p>
        </v-alert>
        <v-alert
          border="right"
          colored-border
          color="green"
          elevation="2"
          v-if="uploaded.length"
        >
          <p>Upload complete: <v-divider></v-divider></p>
          <p v-for="document in uploaded" v-bind:key="document.name">
            {{ document.name }}
          </p>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn @click="backPage"> Back </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="goToFinal"> Continue </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog persistent v-model="needToVerify" width="600px">
      <v-card class="pa-2" flat>
        <v-card-text
          >We will need to verify your identity by sending a code to the phone
          number listed on the booking</v-card-text
        >
        <v-card-actions
          ><v-btn @click="sendVerify">Send Code</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showOTP" width="600px">
      <v-card class="pa-2" flat>
        <v-card-text>
          <v-form>
            <v-container>
              <p class="text-center">
                A verification code was sent to the phone number associated with
                this booking.
              </p>
              <p class="text-center">Please enter it here:</p>
              <v-row>
                <v-spacer></v-spacer>
                <div class="ma-auto position-relative" style="max-width: 300px">
                  <v-otp-input
                    ref="otpVerify"
                    v-model="verifyCode"
                    :disabled="loading"
                    @finish="getBooking"
                    type="number"
                  ></v-otp-input>
                  <v-overlay absolute :value="loading">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-overlay>
                </div>

                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-text v-if="verifyTimer != 0">
          You may resend the code in {{ verifyTimer }} seconds
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="verifyTimer != 0" @click="sendVerify"
            >Resend code</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      {{ snackbarText }}
    </v-snackbar>
  </v-card>
</template>

<script>
// eslint-disable-next-line
import axios from "axios";
import { mapMutations } from "vuex";
import { v4 as uuidv4 } from "uuid";
import router from "@/router";

export default {
  name: "FileUpload",
  data: () => ({
    files: [],
    e1: 0,
    documents: [],
    uploading: [],
    uploaded: [],
    failed: [],
    e_Mail: "",
    emailRules: [],
    uploadPercentage: 0,
    sendingSMS: false,
    currentlyUploading: "",
    verifyCode: "",
    showOTP: false,
    loading: false,
    needToVerify: false,
    bookingID: "",
    snackbarColor: "default",
    snackbar: false,
    snackbarText: "",
    booking: false,
    verifyTimer: 0,
    loadingBooking: false,
  }),
  created() {
    this.bookingID = this.$route.params.id;
    this.getBooking();
  },
  async mounted() {
    // await this.countDownTimer();
  },
  computed: {},
  methods: {
    ...mapMutations(["setBooking"]),
    async getBooking() {
      console.log("in get booking");
      this.loading = true;
      var params = {};
      if (this.verifyCode != "") {
        console.log("verifying with OTP");
        params["otp"] = this.verifyCode;
      } else if (this.$cookies.get("jwt") == null) {
        console.log("jwt is not set");
        this.needToVerify = true;
        return false;
      } else {
        console.log("verifying with jwt");
        params["jwt"] = this.$cookies.get("jwt");
      }
      params["bookingid"] = this.bookingID;
      this.loadingBooking = true;
      axios
        .post(
          "https://q6gzc34glidyfqclsaeidjswjm0vuybu.lambda-url.us-west-2.on.aws/",
          params
        )
        .then((response) => {
          if (this.$cookies.get("jwt") == null) {
            this.$cookies.set("jwt", response["data"]["jwt"], "1h");
          }
          this.booking = JSON.parse(response["data"]["booking"]);
          this.loading = false;
          this.showOTP = false;
          this.loadingBooking = false;
        })
        .catch((error) => {
          this.$cookies.remove("jwt");
          console.log("error");
          console.log(error);
          if (error.response.data.error == "incorrect otp") {
            this.snackbarColor = "warning";
            this.snackbarText = "Incorrect OTP code, please try again";
            this.snackbar = true;
            this.showOTP = true;
            this.timingVerify = false;
          } else if (error.response.data.error == "invalid jwt") {
            this.$cookies.set("jwt", null);
            this.showOTP = false;
            this.needToVerify = true;
          } else {
            console.log(error);
            this.showOTP = false;
            this.snackbarColor = "warning";
            this.snackbarText =
              "There was an unknown error, please contact support";
            this.snackbar = true;
          }
          this.loading = false;
          this.loadingBooking = false;
        });
    },
    goToFinal() {
      router.push({ name: "final" });
    },
    async fileSelected() {
      this.files.forEach((file) => {
        file["id"] = uuidv4();
        this.upload(file);

        this.documents.push({
          name: file.name,
          status: "uploading",
        });
        this.uploading.push({
          name: file.name,
          id: file.id,
        });
      });
      this.files = [];
    },
    async upload(file) {
      await this.performUpload(file)
        .then((response) => {
          console.log(response);
          this.uploading = this.uploading.filter(
            (document) => document.id != file.id
          );
          this.currentlyUploading = "";
          this.uploaded.push({
            name: file.name,
            id: file.id,
          });
        })
        .catch((error) => {
          console.log(error);
          this.uploading = this.uploading.filter(
            (document) => document.id != file.id
          );
          this.currentlyUploading = "";
          this.failed.push({
            name: file.name,
            id: file.id,
          });
        });
    },
    async performUpload(file) {
      console.log(file);
      console.log("bookingid", this.bookingID);
      this.currentlyUploading = file.name;
      if (this.$cookies.get("jwt") == null) {
        this.needToVerify = true;
      } else {
        try {
          console.log("trying to get url");
          var response = await axios.post(
            "https://6ro4q2nfwvotyrfhwazg3ceqpy0gocch.lambda-url.us-west-2.on.aws/",
            {
              booking: this.bookingID,
              filename: file.name,
              jwt: this.$cookies.get("jwt"),
            }
          );
          console.log(response);
          return axios.put(response["data"]["upload-url"], file, {
            headers: {
              "Content-Type": file.type,
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          });
        } catch (error) {
          console.log(error);
          if (error.response.data.error == "invalid jwt") {
            this.$cookies.set("jwt", null);
            this.needToVerify = true;
          } else {
            console.log(error);
            this.snackbarColor = "warning";
            this.snackbarText =
              "There was an unknown error, please contact support";
            this.snackbar = true;
          }
        }
      }
    },
    async countDownTimer() {
      if (this.verifyTimer > 0) {
        setTimeout(() => {
          this.verifyTimer -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    async sendVerify() {
      this.loading = true;
      this.needToVerify = false;
      this.showOTP = true;
      axios
        .post(
          "https://i7jccun6pxn7pqad7khb7h3v5a0upnjt.lambda-url.us-west-2.on.aws",
          { booking_id: this.bookingID }
        )
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.verifyTimer = 30;
          this.countDownTimer();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.showOTP = false;
          this.snackbarColor = "warning";
          this.snackbarText =
            "There was an error sending the code. Please check your phone number.";
          this.snackbar = true;
        });
    },
    async sendSMS() {
      this.sendingSMS = true;
      var response = await axios.post(
        "https://ps775ikz7o6ucwmjwzqazuu4um0lbxsz.lambda-url.us-west-2.on.aws/",
        { booking: this.bookingID }
      );
      console.log(response);
      this.sendingSMS = false;
    },
  },
};
</script>
