<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">Contract:</h2>
        <p>Customer: {{ customerFirstName }} {{ customerLastName }}</p>
        Contract text goes here
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <h2 class="headline mb-3">
          By clicking the "Sign" button, you indicate that you have read and
          agree to the above contract
        </h2>
        <v-btn
          @click="
            showModal = true;
            signatureDateTime = new Date();
          "
          >Sign</v-btn
        >
      </v-col>
    </v-row>
    <v-dialog v-model="showModal" width="600px">
      <v-card>
        <v-card-title>Signature:</v-card-title>
        <v-card-text
          >By entering your name in the following box, you indicate that you
          have read and agree to the contract</v-card-text
        >
        <v-card-text
          ><v-text-field
            v-model="customerSignature"
            label="Sign by typing your full name"
            required
          ></v-text-field
        ></v-card-text>
        <v-card-text
          >Date/time of signature: {{ signatureDateTime }}</v-card-text
        >
        <v-card-text
          ><v-btn @click="confirmSignature"
            >Confirm Signature</v-btn
          ></v-card-text
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "SignContract",

  data: () => ({
    opposingParty: "",
    showModal: false,
  }),
  created() {
    this.getJurisdictions();
  },
  computed: {
    ...mapState(["jurisdictionList", "opposingParties"]),
    jurisdiction: {
      ...mapState({ get: "jurisdiction" }),
      ...mapMutations({ set: "jurisdiction" }),
    },
    subJurisdiction: {
      ...mapState({ get: "subJurisdiction" }),
      ...mapMutations({ set: "subJurisdiction" }),
    },
    customerFirstName: {
      ...mapState({ get: "customerFirstName" }),
      ...mapMutations({ set: "customerFirstName" }),
    },
    customerLastName: {
      ...mapState({ get: "customerLastName" }),
      ...mapMutations({ set: "customerLastName" }),
    },
    customerEmail: {
      ...mapState({ get: "customerEmail" }),
      ...mapMutations({ set: "customerEmail" }),
    },
    customerPhone: {
      ...mapState({ get: "customerPhone" }),
      ...mapMutations({ set: "customerPhone" }),
    },
    customerSignature: {
      ...mapState({ get: "customerSignature" }),
      ...mapMutations({ set: "customerSignature" }),
    },
    signatureDateTime: {
      ...mapState({ get: "signatureDateTime" }),
      ...mapMutations({ set: "signatureDateTime" }),
    },
    docketNumber: {
      ...mapState({ get: "docketNumber" }),
      ...mapMutations({ set: "docketNumber" }),
    },
  },
  methods: {
    ...mapActions([
      "getJurisdictions",
      "createBooking",
      "confirmSignature",

      // `mapMutations` also supports payloads:
      // 'incrementBy' // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
    ]),
    ...mapMutations(["pushOpposingParty"]),
    // Will need some local only ones too
    // below here is local
    appendOpposingParty() {
      this.pushOpposingParty(this.opposingParty);
      this.opposingParty = "";
      this.$refs.opposingPartyTextBox.focus();
    },
  },
};
</script>
